<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="问答类型名称:">
                        <el-input v-model='filters.name' placeholder="输入问答类型名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            @current-change="selectCurrentRow"
            :cell-style="rowClass"
            highlight-current-row
            style="width: 1030px"
            >
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column prop="TypeName" label="问答类型名称" align="center" min-width="300"></el-table-column>
            <el-table-column prop="IsEnable" label='启用标识' align='center' min-width="100">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable ? "success" : "primary" '>{{scope.row.IsEnable ? "启用" : "禁用"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" :formatter="formatCreateTime"  align="center" min-width="140"></el-table-column>
        </el-table>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAddOrEdit?'新增':'编辑'"
        :visible.sync="addFormDialog"
        v-model="addFormDialog"
        :close-on-click-modal="false"
        width='30%'
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='问答类型名称:' prop='name'>
                    <el-input v-model='dialogForm.name'></el-input>
                </el-form-item>
                <el-form-item label='是否启用:' prop='isEnable'>
                    <el-select v-model="dialogForm.isEnable" placeholder="请选择">
                        <el-option
                        v-for="item in EnableOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getQuestionAnswerTypeList,addQuestionAnswerType,updateQuestionAnswerType,deleteQuestionAnswerType } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return {
            filters: {
                name: '',
            },
            buttonList: [],
            tableData: [],
            listLoading: false,
            isAddOrEdit:true,
            addFormDialog:false,
            addLoading:false,
            dialogForm:{
                name:'',
                isEnable:true,
            },
            dialogFormRules:{
                name:[{required:true,message:'请输入问答类型名称',trigger:'blur'}],
                isEnable:[{required:true,message:'请选择是否启用',trigger:'change'}],
            },
            needEditData: '',
            EnableOptions:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ]
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            this.listLoading = true
            var params = {}
            if(this.filters.name){
                params.typeName = this.filters.name
            }
            getQuestionAnswerTypeList(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.tableData = result.Response
                }else{
                    this.listLoading = false
                }
            })
        },
        // 查询
        getQuestionAnswerTypeList() {
            this.getListData();
        },
        // 新增
        handleAdd() {
            this.dialogForm = {
                name:'',
                isEnable:true
            }
            this.isAddOrEdit = true;
            this.addFormDialog = true;
        },
        // 编辑
        handleEdit() {
            if(this.needEditData){
                this.dialogForm = {
                    name:this.needEditData.TypeName,
                    isEnable:this.needEditData.IsEnable
                }
                this.isAddOrEdit = false;
                this.addFormDialog = true;
            }else{
                this.$message({
                    message: '请选择要编辑的数据',
                    type: 'warning'
                });
            }
        },
        // 删除
        handleDel() {
            if(this.needEditData){
                this.$confirm('确定删除？',"提示",{
                }).then(() => {
                    var params = {
                        ID: this.needEditData.ID
                    }
                    deleteQuestionAnswerType(params).then((res) => {
                        var result = res.data
                        if(result.Success){
                            this.$message({
                                message: result.Message,
                                type: 'success'
                            });
                            this.getListData();
                        }else{
                            this.$message({
                                message: result.Message,
                                type: 'error'
                            });
                        }
                    })
                })
                .catch(() => {

                })
            }else{
                this.$message({
                    message: '请选择要删除的数据',
                    type: 'warning'
                });
            }
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.needEditData = val
        },
        // 提交
        addSubmit() {
            this.$refs['dialogFormRef'].validate(valid => {
                if(valid){
                    this.addLoading = true
                    var params = {
                        TypeName : this.dialogForm.name,
                        IsEnable : this.dialogForm.isEnable
                    }
                    if(this.isAddOrEdit){
                        addQuestionAnswerType(params).then((res) => {
                            var result = res.data
                            if(result.Success){
                                this.$message({
                                    message: result.Message,
                                    type: 'success'
                                });
                                this.getListData();
                                this.addLoading = false;
                                this.addFormDialog = false;
                            }else{
                                this.$message({
                                    message: result.Message,
                                    type: 'error'
                                });
                            }
                        })
                    }else{
                        params.ID = this.needEditData.ID
                        updateQuestionAnswerType(params).then((res) => {
                            var result = res.data
                            if(result.Success){
                                this.$message({
                                    message: result.Message,
                                    type: 'success'
                                });
                                this.getListData();
                                this.addLoading = false;
                                this.addFormDialog = false;
                            }else{
                                this.$message({
                                    message: result.Message,
                                    type: 'error'
                                });
                            }
                        })
                    }
                }
            })
        },
        rowClass({columnIndex}){
            return 'padding:8px 0!important;'
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm");
        },
    },
    mounted() {
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
    .el-input{
        width:217px!important;
    }
</style>