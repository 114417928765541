import { render, staticRenderFns } from "./QuestionAnswerType.vue?vue&type=template&id=e7a409e8&scoped=true"
import script from "./QuestionAnswerType.vue?vue&type=script&lang=js"
export * from "./QuestionAnswerType.vue?vue&type=script&lang=js"
import style0 from "./QuestionAnswerType.vue?vue&type=style&index=0&id=e7a409e8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7a409e8",
  null
  
)

export default component.exports